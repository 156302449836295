<template>
    <custom-table
        :title="$t('pages.module.featuredProduct.title')"
        :subTitle="$t('pages.module.featuredProduct.subTitle')"
        rowKey="id"
        :items="featuredProducts"
        :loading="table.loading"
        selectableRows
        :columns="fields"
        :actions="actions"
        :pagination="table.pagination"
        @action="handleClickAction"
        @selectableRows="handleSelectedRow"
        @changeTable="handleTableChange">
        <template v-slot:product="{ row: record }">
            {{ record.product && record.product.translate && record.product.translate.title ? record.product.translate.title : "-" }}
        </template>
        <template v-slot:watchTag="{ row: record }">
            {{ resolveDatum(record.translations, $root.defaultLanguage.id, 'language_id').watch_tag ?? "-" }}
        </template>
        <template v-slot:status="{ row: record }">
            <span :class="record.active ? 'badge-light-success': 'badge-light-danger'" class="badge">{{ record.active ? $t('common.active') : $t('common.passive') }}</span>
        </template>
        <template v-slot:actions="{ row: record }">
            <div class="d-flex justify-content-end">
                <a v-on:click="fetchRecord(record)" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                    <span class="svg-icon svg-icon-3">
                        <inline-svg src="/media/icons/duotune/art/art005.svg" />
                    </span>
                </a>
                <el-popconfirm :title="$t('messages.sureDelete')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deleteRecord([record.id])">
                    <template #reference>
                        <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                            <span class="svg-icon svg-icon-3">
                                <inline-svg src="/media/icons/duotune/general/gen027.svg"/>
                            </span>
                        </a>
                    </template>
                </el-popconfirm>
            </div>
        </template>
    </custom-table>
    <div class="modal fade" id="kt_modal_featured_product" ref="featuredProductModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header" id="kt_modal_add_customer_header">
                    <h2 class="fw-bolder">{{ form.title }}</h2>
                    <div id="kt_modal_add_customer_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
                        </span>
                    </div>
                </div>
                <el-form @submit.prevent="onSubmit()" :model="form" ref="featuredProductForm">
                    <div class="modal-body py-10 px-lg-17">
                        <div class="scroll-y me-n7 pe-7" id="kt_modal_add_customer_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.featuredProduct.cols.product') }}</label>
                                <el-form-item prop="data.product_id" :rules="$validation.getMessage(['required'])">
                                    <el-select v-model="form.data.product_id" class="w-100" :placeholder="$t('common.chooseSelect')" filterable>
                                        <el-option v-for="(product, productIndex) in products" :key="productIndex" :value="product.id" :label="product.translate.title + ' (' + product.group.name + ')'"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.featuredProduct.cols.watchTag') }}</label>
                                <el-form-item prop="translate.watch_tag">
                                    <el-input v-model="form.translate.watch_tag" type="text"/>
                                </el-form-item>
                            </div>
                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.featuredProduct.cols.description') }}</label>
                                <el-form-item prop="translate.description">
                                    <el-input v-model="form.translate.description" type="textarea" rows="3"/>
                                </el-form-item>
                            </div>
                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('common.sort') }}</label>
                                <el-form-item prop="data.sort" :rules="$validation.getMessage(['required'])">
                                    <el-input-number v-model="form.data.sort" :min="1" />
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('common.status') }}</label>
                                <el-form-item prop="data.active">
                                    <el-radio-group v-model="form.data.active">
                                        <el-radio-button :label="true">{{ $t("common.active") }}</el-radio-button>
                                        <el-radio-button :label="false">{{ $t("common.passive") }}</el-radio-button>
                                    </el-radio-group>
                                </el-form-item>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer flex-center">
                        <button :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="submit" :disabled="form.loading">
                            <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                            <span v-if="form.loading" class="indicator-progress">
                                {{  $t("messages.wait") }}
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import CustomTable from "@/components/custom-table";
export default {
    name: "index",
    props: ['groupID'],
    components: {
        CustomTable
    },
    data(){
        return {
            fields: [
                {
                    name: this.$t("common.id"),
                    key: "id",
                    class: ""
                },
                {
                    name: this.$t("pages.module.featuredProduct.cols.product"),
                    scopedSlots: {customRender: "product"}
                },
                {
                    name: this.$t("pages.module.featuredProduct.cols.watchTag"),
                    scopedSlots: {customRender: "watchTag"}
                },
                {
                    name: this.$t("common.sort"),
                    key: "sort",
                    class: ""
                },
                {
                    name: this.$t("common.status"),
                    scopedSlots: {customRender: "status"}
                },
                {
                    name: this.$t("common.action"),
                    key: "action",
                    scopedSlots: {customRender: "actions"}
                }
            ],
            actions: [
                {
                    name: "new",
                    icon: "bi-plus-lg",
                    label: "btn.add"
                },
                {
                    name: "refresh",
                    icon: "bi-arrow-repeat",
                    label: "btn.refresh"
                },
                {
                    name: "delete",
                    icon: "bi-trash",
                    label: "btn.delete"
                }
            ],
            form: {
                title: '',
                loading: false,
                updateStatus: false,
                translate: {
                    language_id: this.$root.defaultLanguage.id,
                },
                data: {}
            },
            selectedRowKeys: []
        }
    },
    computed: {
        table() {
            return this.$store.state.module.featuredProduct.table;
        },
        featuredProducts(){
            let featuredProducts = this.table.data.map((featuredProduct) => {
                if(featuredProduct.product) {
                    featuredProduct.product.translate = {};

                    if(featuredProduct.product.translations && Array.isArray(featuredProduct.product.translations)) {
                        featuredProduct.product.translate = this.resolveDatum(featuredProduct.product.translations, this.$root.defaultLanguage.id, 'language_id')
                    }
                }

                return featuredProduct;
            })

            return featuredProducts;
        },
        products() {
            let products = this.$store.state.ecommerce.productManagement.product.indexRaw.table.data.map((product) => {
                product.translate = this.resolveDatum(product.translations, this.$root.defaultLanguage.id, 'language_id')

                return product;
            })

            return products;
        },
    },
    mounted(){
         this.$store.dispatch('module/featuredProduct/get', {
            page: {},
             filterData: {
                group_id: this.groupID
             }
        });

        this.$store.dispatch('ecommerce/productManagement/product/indexRaw/get', {
            page: {pageSize: 9999}
        });
    },
    methods:{
        newRecord(){
            this.form.updateStatus = false;
            this.form.translate = {
                language_id: this.$root.defaultLanguage.id
            };
            this.form.data = {
                group_id: this.groupID,
                sort: 1,
                active: true
            };
            this.form.title = this.$t("pages.module.featuredProduct.new");
            this.showModal(this.$refs.featuredProductModal);
        },
        fetchRecord(record) {
            this.form.updateStatus = true;
            this.form.title = this.$t("pages.module.featuredProduct.edit");
            this.axios.get(this.endpoints['module_featured_product']+ '/' + record.id).then(response => {
                let data = response.data.data;
                this.form.data = data;
                this.form.translate = this.resolveDatum(data.translations, this.$root.defaultLanguage.id, 'language_id');

                this.showModal(this.$refs.featuredProductModal);
            });
        },
        onSubmit(){
            this.$refs.featuredProductForm.validate((valid) => {
                if(valid) {
                    this.form.loading = true;
                    let formData = this.prepareFormData();

                    if(this.form.data.id) {
                        this.axios.put(this.endpoints['module_featured_product'] + '/' + this.form.data.id, formData).then(response => {
                            this.onResponse(response.data, () => {
                                this.refreshTable();
                                this.hideModal(this.$refs.featuredProductModal);
                            }, () => {
                                this.form.loading = false;
                            });
                        }).catch(error => {
                            this.onResponseFailure(error.response.data, () => {
                                this.form.loading = false;
                            });
                        });
                    }else {
                        this.axios.post(this.endpoints['module_featured_product'], formData).then(response => {
                            this.onResponse(response.data, () => {
                                this.refreshTable();
                                this.hideModal(this.$refs.featuredProductModal);
                            }, () => {
                                this.form.loading = false;
                            });
                        }).catch(error => {
                            this.onResponseFailure(error.response.data, () => {
                                this.form.loading = false;
                            });
                        });
                    }
                } else {
                    return false;
                }
            });
        },
        prepareFormData(){
            let formData = this.form.data;

            return {...this.form.translate, ...formData};
        },
        deleteRecord(id){
            this.$store.dispatch("module/featuredProduct/delete", {
                id: id
            }).then((successDeleted) => {
                this.selectedRowKeys = this.selectedRowKeys.filter(x => !successDeleted.includes(x));
            });
        },
        handleClickAction(name) {
            switch (name) {
                case "refresh":
                    this.refreshTable();
                    break;

                case "new":
                    this.newRecord();
                    break;

                case "delete":
                    this.deleteRecord(this.selectedRowKeys.flat());
                    break;

                default:
                    break;
            }
        },
        refreshTable(){
            this.$store.dispatch("module/featuredProduct/refresh");
        },
        handleTableChange(pagination, filterData) {
            this.$store.dispatch("module/featuredProduct/get", {
                page: pagination,
                filterData: Object.assign({
                    group_id: this.groupID
                }, filterData)
            });
        },
        handleSelectedRow(record){
            this.selectedRowKeys = record;
        }
    }
}
</script>

<style scoped>

</style>