<template>
    <div class="d-flex flex-wrap flex-stack my-5">
        <h2 class="fs-2 fw-bold my-2">
            <template v-if="groupID">
                {{ sprintf($t('pages.module.featuredProduct.group.save.edit'), [featuredProductGroup.translate.name ?? '']) }}
            </template>
            <template v-else>{{ $t('pages.module.featuredProduct.group.save.new') }}</template>
        </h2>

        <router-link to="/module/featured-product/group" class="btn btn-primary align-self-center ms-4 back-page-btn">
            <span class="svg-icon svg-icon-2">
                <inline-svg src="/media/icons/duotune/arrows/arr021.svg" />
            </span>
            {{ $t("pages.module.featuredProduct.group.title") }}
        </router-link>
    </div>
    <el-tabs v-model="tabModel" class="paragraph-tabs">
        <el-tab-pane :label="$t('pages.module.featuredProduct.group.save.tabs.generalDetails')" name="general">
            <el-form :model="form" ref="featuredProductForm">
                <div class="row g-6 mb-6 g-xl-9 mb-xl-9">
                    <div class="col-md-8">
                        <div class="card">
                            <div class="card-body d-flex flex-column p-9">
                                <div class="fv-row mb-1">
                                    <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.featuredProduct.group.cols.name') }}</label>
                                    <el-form-item prop="translate.name" :rules="$validation.getMessage(['required'])">
                                        <el-input v-model="form.translate.name" type="text"/>
                                    </el-form-item>
                                </div>
                                <div class="fv-row mb-1">
                                    <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.featuredProduct.group.save.cols.title') }}</label>
                                    <el-form-item prop="translate.title" :rules="$validation.getMessage(['required'])">
                                        <CustomCkeditor :model="form.translate.title" @updateModel="form.translate.title = $event" :basicEditor="true"></CustomCkeditor>
                                    </el-form-item>
                                </div>
                                <div class="fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.featuredProduct.group.save.cols.description') }}</label>
                                    <el-form-item prop="translate.description">
                                        <CustomCkeditor :model="form.translate.description" @updateModel="form.translate.description = $event" :basicEditor="true"></CustomCkeditor>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card">
                            <div class="card-body d-flex flex-column p-9">
                                <div class="fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('common.sort') }}</label>
                                    <el-form-item prop="data.sort" :rules="$validation.getMessage(['required'])">
                                        <el-input-number v-model="form.data.sort" :min="1"/>
                                    </el-form-item>
                                </div>

                                <div class="fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('common.status') }}</label>
                                    <el-form-item prop="data.active">
                                        <el-radio-group v-model="form.data.active">
                                            <el-radio-button :label="true">{{ $t("common.active") }}</el-radio-button>
                                            <el-radio-button :label="false">{{ $t("common.passive") }}</el-radio-button>
                                        </el-radio-group>
                                    </el-form-item>
                                </div>
                                <div class="fv-row mt-5">
                                    <el-form-item class="mb-0">
                                        <button @click.prevent="onSubmit" :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary w-100" type="button">
                                            <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                                            <span v-if="form.loading" class="indicator-progress">
                                                {{ $t("messages.wait") }}
                                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                            </span>
                                        </button>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </el-form>
        </el-tab-pane>
        <el-tab-pane v-if="form.updateStatus" :label="$t('pages.module.featuredProduct.group.save.tabs.productSettings')" name="productSettings">
            <Product :groupID="groupID" v-if="tabModel == 'productSettings'"></Product>
        </el-tab-pane>
    </el-tabs>
</template>

<script>
import CustomCkeditor from "@/components/custom-ckeditor";
import Product from "@/components/module/featured-product/group/product";
export default {
    name: "_id",
    components: {
        CustomCkeditor,
        Product
    },
    data() {
        return {
            tabModel: 'general',
            form: {
                updateStatus: false,
                loading: false,
                translate: {
                    language_id: this.$root.defaultLanguage.id
                },
                data: {
                    sort: 1,
                    active: true
                },
            },
            featuredProductGroup: {
                translate: {}
            }
        }
    },
    computed: {
        groupID() {
            return this.$route.params.id;
        }
    },
    created() {
        if (this.groupID && !(this.groupID > 0)) {
            this.$router.push({
                path: "/module/featured-product/group"
            });
        }
    },
    mounted() {
        if (this.groupID && this.groupID > 0) {
            this.loadFeaturedProductGroup();
        }
    },
    methods: {
        loadFeaturedProductGroup(groupID = this.groupID) {
            this.axios.get(this.endpoints['module_featured_product_group'] + '/' + groupID).then((response) => {
                let data = response.data.data;

                data.translate = this.resolveDatum(data.translations, this.form.translate.language_id, 'language_id');

                this.featuredProductGroup = data;
                this.form.translate = this.cloneData(data.translate);

                this.form.updateStatus = true;
                this.form.data = data;
            })
        },
        onSubmit() {
            this.$refs.featuredProductForm.validate((valid) => {
                if (valid) {
                    this.form.loading = true;
                    let formData = this.prepareFormData();

                    if (this.form.data.id) {
                        this.axios.put(this.endpoints['module_featured_product_group'] + '/' + this.form.data.id, formData).then(response => {
                            this.onResponse(response.data, () => {
                                this.loadFeaturedProductGroup();
                            }, () => {
                                this.form.loading = false;
                            });
                        }).catch(error => {
                            this.onResponseFailure(error.response.data, () => {
                                this.form.loading = false;
                            });
                        });
                    } else {
                        this.axios.post(this.endpoints['module_featured_product_group'], formData).then(response => {
                            this.onResponse(response.data, () => {
                                let groupID = response.data.data.id;
                                this.$router.push({
                                    path: "/module/featured-product/group/save/" + groupID
                                });
                                this.loadFeaturedProductGroup(groupID);
                            }, () => {
                                this.form.loading = false;
                            });
                        }).catch(error => {
                            this.onResponseFailure(error.response.data, () => {
                                this.form.loading = false;
                            });
                        });
                    }
                } else {
                    return false;
                }
            });
        },
        prepareFormData() {
            let formData = this.form.data;

            return {...this.form.translate, ...formData};
        },
    }
}
</script>

<style scoped>

</style>